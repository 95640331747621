* {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
  position: relative;
  user-select: none;
}
