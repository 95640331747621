@font-face {
  font-family: 'Signifier';
  src: url('../resources/fonts/Signifier-Light.otf') format('opentype');
}

@font-face {
  font-family: 'DMSans';
  src: url('../resources/fonts/DMSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DMSans';
  src: url('../resources/fonts/DMSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'DMSans';
  src: url('../resources/fonts/DMSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DMSans';
  src: url('../resources/fonts/DMSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'DMSans';
  src: url('../resources/fonts/DMSans-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DMSans';
  src: url('../resources/fonts/DMSans-BoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'DMMonoLight';
  src: url('../resources/fonts/DMMonoLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DMMonoRegular';
  src: url('../resources/fonts/DMMonoRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fake Receipt';
  src: url('../resources/fonts/fake-receipt.ttf');
}
